import * as Yup from "yup";
import get from "lodash/get";
import moment from "moment";

export const getExceptionValidationSchema = (
  messages,
  Localise,
  allExceptions
) => {
  return Yup.object().shape({
    label: Yup.string()
      .required(Localise(messages, "Please enter Exception Label"))
      .test(
        "labelTest",
        Localise(messages, "Exception Label must be unique"),
        function (value) {
          const exceptionId = get(this, "parent.exceptionId", "");
          const isDuplicatelabel = allExceptions.some(
            (exception) =>
              get(exception, "label", "") === value &&
              get(exception, "exceptionId", "") !== exceptionId
          );
          return !isDuplicatelabel;
        }
      ),
    criteria: Yup.string().required(
      Localise(messages, "Please select Exception Criteria")
    ),
    cityZipInfo: Yup.object().shape({
      city: Yup.string().test(
        "city",
        Localise(messages, "Select City"),
        function (value) {
          if (
            get(this.options, "from.1.value.criteria", "") ===
              "CITY_ZIP_CODE" &&
            !value
          ) {
            return false;
          }
          return true;
        }
      ),
    }),
    rule: Yup.string().required(
      Localise(messages, "Please select Exception Rule")
    ),
    additionalFee: Yup.string().test(
      "additionalFee",
      Localise(messages, "Please Enter Additional Fee"),
      function (value) {
        const rule = get(this, "parent.rule", "");
        if (rule === "ADDITIONAL_FEE" && !value) {
          return false;
        }
        return true;
      }
    ),
    startDate: Yup.string().test(
      "startDate",
      Localise(messages, "End date is less than start date."),
      function (value) {
        const { endDate } = this.parent;
        const isEndDateAfterStart = endDate && value && moment(value).isAfter(endDate);
        return !isEndDateAfterStart;
      }
    ),
    endDate: Yup.string().test(
      "endDate",
      Localise(messages, "End date is less than start date."),
      function (value) {
        const { startDate } = this.parent;
        const isEndDateAfterStart = startDate && value && moment(startDate).isAfter(value);
        return !isEndDateAfterStart;
      }
    ),
  });
};

export const getGlobalFeesValidationSchema = (messages, Localise) => {
  return Yup.object().shape({
    localFee: Yup.string().inValidFees(Localise(messages, "Invalid Fee"), true),
    outsideLocalFee: Yup.string().inValidFees(
      Localise(messages, "Invalid Fee"),
      true
    ),
    serviceFee: Yup.string().inValidFees(
      Localise(messages, "Invalid Fee"),
      true
    ),
    relayFee: Yup.string().required(Localise(messages, "Invalid Fee")),
    rushDeliveryInfos: Yup.array()
      .when("enableRushFee", {
        is: true,
        then: Yup.array().of(
          Yup.object().shape({
            rushFee: Yup.string().test(
              "rush-fee-valid",
              Localise(messages, "Invalid Fee"),
              function (value) {
                if (!value || Number(value) < 0) {
                  return this.createError({
                    path: this.path,
                    message: Localise(messages, "Invalid Fee"),
                  });
                }
                return true;
              }
            ),
            submittedBy: Yup.date()
              .required(Localise(messages, "Submitted By is required"))
              .test(
                "submittedBeforeDelivered",
                Localise(messages, "Invalid time"),
                function (value) {
                  const { deliveredBy } = this.parent;
                  if (
                    value &&
                    deliveredBy &&
                    moment(value).isSameOrAfter(moment(deliveredBy))
                  ) {
                    return this.createError({
                      path: this.path,
                      message: Localise(messages, "Invalid time"),
                    });
                  }
                  return true;
                }
              ),
            deliveredBy: Yup.date()
              .required(Localise(messages, "Delivered By is required"))
              .test(
                "deliveredAfterSubmitted",
                Localise(messages, "Invalid time"),
                function (value) {
                  const { submittedBy } = this.parent;
                  if (
                    value &&
                    submittedBy &&
                    moment(value).isSameOrBefore(moment(submittedBy))
                  ) {
                    return this.createError({
                      path: this.path,
                      message: Localise(messages, "Invalid time"),
                    });
                  }
                  return true;
                }
              ),
          })
        ),
      })
      .test(
        "no-overlapping-times",
        Localise(messages, `Invalid time`),
        function (rushDeliveryInfos) {
          // Use reduce to track the invalid index
          const invalidIndex = rushDeliveryInfos.reduce(
            (acc, current, index) => {
              const currentSubmitted = moment(current.submittedBy);

              const isValid = rushDeliveryInfos
                .slice(0, index)
                .every((previous) => {
                  const previousDelivered = moment(previous.deliveredBy);

                  return currentSubmitted.isAfter(previousDelivered);
                });

              return isValid ? acc : index;
            },
            -1
          );

          if (invalidIndex !== -1) {
            const errorPath = `rushDeliveryInfos[${invalidIndex}].submittedBy`;

            return this.createError({
              path: errorPath,
              message: Localise(messages, `Invalid time`),
            });
          }

          return true;
        }
      ),
  });
};

export const getCitiesFeesValidationSchema = (messages, Localise) => {
  return Yup.array().of(
    Yup.object().shape({
      fee: Yup.string().inValidFees(Localise(messages, "Cities Fee"), true),
    })
  );
};

Yup.addMethod(
  Yup.string,
  "inValidFees",
  function (errorMessage, skipStatusCheck) {
    return this.test("invalid-fees", errorMessage, function (value) {
      const { path, createError, parent } = this;

      let { status, serviceFee, isActive } = parent;

      if (path === "serviceFee") {
        return createError({
          path,
          message: isActive === "true" && !serviceFee ? errorMessage : "",
        });
      }

      status = skipStatusCheck || status;
      if (
        status &&
        (value === "" || value === undefined || (value !== "" && isNaN(value)))
      )
        return createError({
          path,
          message: errorMessage,
        });
      return createError({ path, message: "" });
    });
  }
);

export const getNewCoverageAreaValidationSchema = (messages, Localise) => {
  return Yup.object().shape({
    cityZipcodesCoverage: Yup.array().of(
      Yup.object().shape({
        fee: Yup.string().inValidFees(Localise(messages, "Zip Code Fee")),
      })
    ),
  });
};

export const getCityZipcodesValidationSchema = (messages, Localise) => {
  return Yup.array().of(
    Yup.object().shape({
      fee: Yup.string().inValidFees(Localise(messages, "Zip Code Fee")),
    })
  );
};
